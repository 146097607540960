import axios from "axios";

const config = {
  tokenEndpoint:
    "https://scg-token-manager.azurewebsites.net/api/gettokentrigger", // production
  captchaRoute: "recaptcha",
};

export async function verifyCaptchaAsync(captchaToken) {
  try {
    const requestBody = {
      route: config.captchaRoute,
      recaptchaToken: captchaToken,
    };

    axios
      .post(`${config.tokenEndpoint}`, requestBody)
      .then((response) => {
        // Handle the response
        console.log(response.data);
        return true;
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
        throw new Error("Captcha verification failed");
      });
  } catch (error) {
    console.log("Error saving item to SharePoint:", error);
    throw error;
  }
}
