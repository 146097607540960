import { Client } from "@microsoft/microsoft-graph-client";
import axios from 'axios';

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me", //e.g. https://graph.microsoft.com/v1.0/me
  gfhpmGraphSiteId:
    "synergyprotection.sharepoint.com,4c127e1e-75aa-4915-b5ff-f2bd5a7389e5,88e3dd5f-7caa-4de7-a372-8eaab72fc088",
  registrationsListId: "c1fe449a-ac1a-42f1-858e-7227e281ef44",
  graphBaseEndPoint: "https://graph.microsoft.com/v1.0", //e.g. https://graph.microsoft.com/v1.0/me
  tokenEndpoint:
    "https://scg-token-manager.azurewebsites.net/api/gettokentrigger", // production
  tokenRoute: "token",
  //tokenEndpoint: "https://acquire-token.azurewebsites.net/api/gettokentrigger", // localhost
  listId: "c1fe449a-ac1a-42f1-858e-7227e281ef44",
  documentListId: "63d11f83-278b-4711-81bf-f43389a7a374",
  captchaSecretKet: "6LeO-WsmAAAAALL52q9iWnplQf2jEppIhUHhFaok",
  captchaVerifyEndPoint: "https://www.google.com/recaptcha/api/siteverify",
};

export function getAppOnlyBearerToken() {
  const requestBody = {
    route: graphConfig.tokenRoute,
  };

  return axios
    .post(`${graphConfig.tokenEndpoint}`, requestBody)
    .then((response) => {
      // Handle the response
      return {
        access_token: response.data.access_token,
        expires_on: response.data.expires_on,
      };
    })
    .catch((error) => {
      // Handle the error
      console.error(error);
    });
}

export async function uploadFileToSharePointAsync(file, fileName, bearerToken) {
  try {
    const client = Client.init({
      authProvider: (done) => {
        done(null, bearerToken.access_token);
      },
    });

    const response = await client
      .api(
        `/sites/${graphConfig.gfhpmGraphSiteId}/drive/root:/SCGRegistrations/${fileName}:/content`
      )
      .put(file);
    console.log("File uploaded to SharePoint:", response);

    // Update the title same as the file name
    const itemPayload = {
      fields: {
        Title: file.name,
      },
    };
    const responseItemProps = await client
      .api(
        `/sites/${graphConfig.gfhpmGraphSiteId}/drive/items/${response.id}/ListItem`
      )
      .update(itemPayload);
    //console.log('Title updated for ListItem:', listItemResponse.id);

    // Return the ItemID
    return responseItemProps.id;
  } catch (error) {
    console.log("Error uploading file to SharePoint:", error);
    throw error;
  }
}

export async function saveToSharePointAsync(formValues, bearerToken) {
  try {
    const client = Client.init({
      authProvider: (done) => {
        done(null, bearerToken.access_token);
      },
    });

    const itemPayload = {
      fields: {
        FirstName: formValues.firstName,
        MiddleName: formValues.middleName,
        LastName: formValues.lastName,
        PhoneNumber: formValues.phoneNumber,
        AlternativeNumber: formValues.alternativeNumber,
        Email: formValues.email,
        CurrentCrossingGuardEmployer: formValues.currentEmployer,
        CurrentIntersection: formValues.currentIntersection,
        CurrentShift: formValues.currentShift,
        PreviousEmploymentStatus: formValues.employmentStatus,
        HasVulnerableSectorCheck: formValues.hasVulnerableSectorCheck,
        HasTransportation: formValues.hasTransportation,
        HasSmartphone: formValues.hasSmartphone,
        Comments: formValues.comments,
      },
    };

    // Add YearsExperience to payload only if it is a valid number
    const yearsExperience = Number(formValues.yearsOfExperience);
    if (!isNaN(yearsExperience)) {
      itemPayload.fields.YearsExperience = yearsExperience;
    }

    // Change ExpiryDate format to a compatible format for SharePoint date column
    if (formValues.expiryDate) {
      const expiryDate = new Date(formValues.expiryDate).toISOString();
      itemPayload.fields.ExpiryDate = expiryDate;
    }

    // attach the file ID too
    const attachmentId = Number(formValues.attachmentId);
    if (!isNaN(attachmentId)) {
      itemPayload.fields.DocumentLookupId = attachmentId;
    }

    // Add Title field as FirstName + LastName
    itemPayload.fields.Title = formValues.firstName + " " + formValues.lastName;

    const response = await client
      .api(
        `/sites/${graphConfig.gfhpmGraphSiteId}/lists/${graphConfig.listId}/items`
      )
      .post(itemPayload);
    console.log("Item saved to SharePoint:", response);
  } catch (error) {
    console.log("Error saving item to SharePoint:", error);
    throw error;
  }
}
