import "./App.css";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Modal,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import {
  getAppOnlyBearerToken,
  saveToSharePointAsync,
  uploadFileToSharePointAsync,
} from "./SharePoint.Service";
import {
verifyCaptchaAsync
} from "./Captcha.Service";

const initialFormState = {
  firstName: "",
  middleName: "",
  lastName: "",
  phoneNumber: "",
  alternativeNumber: "",
  email: "",
  currentEmployer: "",
  currentIntersection: "",
  currentShift: "",
  employmentStatus: "",
  yearsOfExperience: "",
  hasVulnerableSectorCheck: "",
  expiryDate: "",
  hasTransportation: "",
  hasSmartphone: "",
  comments: "",
  attachment: null,
};

function App() {
  const [formValues, setFormValues] = useState(initialFormState);
  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false); // State for submitting status
  const [showThankYou, setShowThankYou] = useState(false); // State for showing thank you dialog
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const recaptchaRef = React.createRef();
  const [captchaToken, setCaptchaToken] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      const token = await getAppOnlyBearerToken();
      setToken(token);
    };

    getToken();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormValues({ ...formValues, attachment: file });
  };

  const sanitizeFileName = (fileName) => {
    const timestamp = Date.now();
    const sanitizedFileName = fileName.replace(/[^\w\s.-]/g, "_");
    return `${timestamp}_${sanitizedFileName}`;
  };

  const handleSubmit = async (event) => {
    if(!captchaToken) {
      event.preventDefault();
      event.stopPropagation();
      setSubmitting(false);
      setError(true);
      setErrorMessage(
        "Please complete the reCaptcha verification to proceed."
      );
      return;
    }
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setShowError(true);
      } else {
        setShowError(false);
        event.preventDefault(); // Prevent the form from submitting immediately

        if (token) {
          setShowThankYou(false); // Show the thank you dialog
          setSubmitting(true); // Set submitting status to true
          verifyCaptchaAsync(captchaToken).then(() => submitToSharePoint()).catch((error) => {
            console.log("Error uploading file to SharePoint:", error);
            setSubmitting(false);
            setError(true);
            setErrorMessage(
              "Error uploading file to SharePoint: " + error.message
            );
          });
          
        } else {
          console.log("Bearer token not available");
          // Handle the case when the bearer token is not available
        }
      }
      setValidated(true);
  };

  const submitToSharePoint = () => {
    // checkfor attachment and save to SharePoint
    if (formValues.attachment) {
      const sanitizedFileName = sanitizeFileName(
        formValues.attachment.name
      );
      uploadFileToSharePointAsync(
        formValues.attachment,
        sanitizedFileName,
        token
      )
        .then((itemId) => {
          formValues.attachmentId = itemId;
          saveToSharePointAsync(formValues, token)
            .then(() => {
              console.log("Data saved to SharePoint");
              setSubmitting(false);
              setShowThankYou(true);
            })
            .catch((error) => {
              console.log("Error saving data to SharePoint:", error);
              setSubmitting(false);
              setError(true);
              setErrorMessage(
                "Error saving data to SharePoint: " + error.message
              );
            });
        })
        .catch((error) => {
          console.log("Error uploading file to SharePoint:", error);
          setSubmitting(false);
          setError(true);
          setErrorMessage(
            "Error uploading file to SharePoint: " + error.message
          );
        });
    } else {
      saveToSharePointAsync(formValues, token, captchaToken)
        .then(() => {
          console.log("Data saved to SharePoint");
          setSubmitting(false);
          setShowThankYou(true);
        })
        .catch((error) => {
          console.log("Error saving data to SharePoint:", error);
          setSubmitting(false);
          setError(true);
          setErrorMessage(
            "Error saving data to SharePoint: " + error.message
          );
        });
    }
  }

  const handleCloseThankYou = () => {
    setShowThankYou(false); // Close the thank you dialog
    handleClear(); // Reset the form
  };

  const handleClear = () => {
    setFormValues(initialFormState);

    // Clear validation state
    setValidated(false);

    // Reset the reCAPTCHA
    setCaptchaToken("");
    recaptchaRef.current.reset();
    
    // Reset the file control
    const fileControl = document.getElementById("attachment");
    if (fileControl) {
      fileControl.value = null;
    }
  };

  return (
    <Container className="form-container">
      <Row>
        <Col>
          <div className="header">
            <div className="logo">
              <br />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="pt-4">
            <h1>We are excited to meet you!</h1>
            <p>
              We are thrilled to have this opportunity to connect with you.
              Synergy is proud to announce its takeover of Zone 2 for the city
              of Toronto Crossing Guards Services, and we invite you to be a
              part of our dedicated team by registering below. Your registration
              will allow us to gauge your interest in joining Synergy and
              supporting your school and community throughout the upcoming
              school year.
            </p>
            <p>
              At Synergy, we prioritize our employees and foster a work
              environment centered around their needs. Our organization is
              committed to creating an engaged, positive work culture where
              every team member feels valued and motivated. We firmly believe
              that a supportive and empowering workplace leads to exceptional
              service delivery. Together, we can make a meaningful difference in
              students, parents, and educators' lives.
            </p>
            <p>
              Please take a moment to complete the registration form below and
              let us know about your interest in joining the Synergy team. We
              appreciate your dedication to your school and community and look
              forward to the incredible impact we can make together.
            </p>
          </div>

          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="heading-box">
              <h4 className="pb-3 pt-3 pl-2 gradient-heading">
                Contact Information
              </h4>
              <Row className="mb-3">
                <Form.Group as={Col} sm={4} xs={12} controlId="firstName">
                  <Form.Label>First Name*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your first name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} sm={4} xs={12} controlId="middleName">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="middleName"
                    value={formValues.middleName}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group as={Col} sm={4} xs={12} controlId="lastName">
                  <Form.Label>Last Name*</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="lastName"
                    value={formValues.lastName}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide your last name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} sm={4} xs={12} controlId="phoneNumber">
                  <Form.Label>Phone Number*</Form.Label>
                  <Form.Control
                    required
                    pattern="[0-9]{10}"
                    type="text"
                    placeholder="10 digits"
                    name="phoneNumber"
                    value={formValues.phoneNumber}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide valid 10 digit phone number.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm={4}
                  xs={12}
                  controlId="alternativeNumber"
                >
                  <Form.Label>Alternative Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="alternativeNumber"
                    pattern="[0-9]{10}"
                    placeholder="10 digits"
                    value={formValues.alternativeNumber}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide valid 10 digit phone number.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} xs={12} controlId="email">
                  <Form.Label>Email Address*</Form.Label>
                  <Form.Control
                    required
                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                    type="email"
                    name="email"
                    // value={formValues.email}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </div>

            <div className="heading-box">
              <h4 className="pb-3 pt-3 pl-2 gradient-heading">Employment</h4>

              <Row className="mb-3">
                <Form.Group as={Col} sm={6} xs={12} controlId="employer">
                  <Form.Label>Current Crossing Guard Employer*</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="currentEmployer"
                    value={formValues.currentEmployer}
                    onChange={handleChange}
                  >
                    <option value="">Choose...</option>
                    <option value="ASP">ASP</option>
                    <option value="Carraway">Carraway</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide your current employer.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={6} xs={12} controlId="experience">
                  <Form.Label>Years of Experience*</Form.Label>
                  <Form.Control
                    name="yearsOfExperience"
                    onChange={handleChange}
                    as="select"
                    required
                    value={formValues.yearsOfExperience}
                  >
                    <option value="">Please select</option>
                    {Array.from({ length: 40 }, (_, index) => (
                      <option key={index} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide the number of years of experience.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} sm={6} xs={12} controlId="shift">
                  <Form.Label>Current Shift*</Form.Label>
                  <Form.Select
                    required
                    name="currentShift"
                    value={formValues.currentShift}
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="2 Shift">2 Shift</option>
                    <option value="3 Shift">3 Shift</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Please provide your current shift.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  as={Col}
                  sm={6}
                  xs={12}
                  controlId="employmentStatus"
                >
                  <Form.Label>Employment Status*</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="employmentStatus"
                    value={formValues.employmentStatus}
                    onChange={handleChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="Full-time">Full-time</option>
                    <option value="Part-time">Part-time</option>
                    <option value="Casual">Casual</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select your employment status.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  sm={6}
                  xs={12}
                  controlId="vulnerableSectorCheck"
                >
                  <Form.Label>
                    Do you have a Vulnerable Sector Check?*
                  </Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="hasVulnerableSectorCheck"
                    value={formValues.hasVulnerableSectorCheck}
                    onChange={handleChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Expired">Expired</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select whether you have a Vulnerable Sector Check.
                  </Form.Control.Feedback>
                </Form.Group>

                {formValues.hasVulnerableSectorCheck === "Yes" && (
                  <Form.Group as={Col} sm={6} xs={12} controlId="expiryDate">
                    <Form.Label>Vulnerable Sector Check Expiry Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="expiryDate"
                      value={formValues.expiryDate}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} sm={12} xs={12} controlId="intersection">
                  <Form.Label>Current Intersection/Location</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    name="currentIntersection"
                    value={formValues.currentIntersection}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
            </div>

            <div className="heading-box">
              <h4 className="pb-3 pt-3 pl-2 gradient-heading">
                Other Information
              </h4>
              <Row className="mb-3">
                <Form.Group as={Col} sm={6} xs={12} controlId="transportation">
                  <Form.Label>
                    Do you have access to reliable transportation?*
                  </Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="hasTransportation"
                    value={formValues.hasTransportation}
                    onChange={handleChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select whether you have access to reliable
                    transportation.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} sm={6} xs={12} controlId="smartphone">
                  <Form.Label>Do you have a smartphone?*</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="hasSmartphone"
                    value={formValues.hasSmartphone}
                    onChange={handleChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select whether you have a smartphone.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} sm={12} xs={12} controlId="comments">
                  <Form.Label>
                    {`If you have any additional information that you would like to provide, please feel free to leave it in the comment box below.`}
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="comments"
                    value={formValues.comments}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Row>
            </div>
            <div className="heading-box">
              <h4 className="pb-3 pt-3 pl-2 gradient-heading">
                Upload CV (Optional)
              </h4>
              <Row className="mb-3">
                <Form.Group as={Col} sm={6} xs={12}>
                  <Form.Control
                    type="file"
                    name="attachment"
                    id="attachment" // Add this line to set the id attribute
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                  />
                  <Form.Text>
                    We support the following file formats: PDF, DOC, DOCX & ZIP.
                  </Form.Text>
                </Form.Group>
              </Row>
            </div>
            <div className="error-message">
              {showError && (
                <Alert variant="danger">
                  Uh-oh! Please fix the errors in the form and try submitting
                  again.
                </Alert>
              )}
            </div>
            <Row>
              <Col>
                <ReCAPTCHA
                  sitekey="6LeO-WsmAAAAAPGv5ZldEjlIMAE3gmhSAV_znmlX"
                  onChange={handleCaptchaChange}
                  ref={recaptchaRef}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Text className="text-secondary">
                  {`By submitting this registration form, you acknowledge that all information provided is true and accurate to the best of your knowledge. Thank you for your cooperation and trust; we are looking forward to working with you.`}
                </Form.Text>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Button
                  variant="primary"
                  type="submit"
                  //onClick={handleSubmit}
                  className="me-3"
                >
                  {submitting ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  className="ml-2"
                  onClick={handleClear}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="footer">
            <p>
              For any questions or concerns, please contact our HR department at{" "}
              <a href="mailto:hr@getsynergy.ca">hr@getsynergy.ca</a>.
            </p>
          </div>
        </Col>
      </Row>
      <Modal show={showThankYou} onHide={handleCloseThankYou}>
        <Modal.Header closeButton onAbort={handleCloseThankYou}>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your form has been successfully submitted.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseThankYou}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {error && (
        <Modal show={error} onHide={() => setError(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Alert variant="danger">
            {errorMessage.indexOf("reCaptcha") === -1 ? (
              <>
                Error processing form. Please try again later.
                <br />
                Error Details: {errorMessage}
              </>
            ) : (
              <>
                {errorMessage}
              </>
            )}
          </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setError(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
}

export default App;
